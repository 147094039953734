import React from 'react';
import "./reset.scss"
import "./App.scss"
import AppRouter from "./router/AppRouter";

function App() {

    return (
        <>
            <AppRouter/>
        </>

    );
}

export default App;


